<template>
    <div>
        <v-container>

            <div v-if="isLogged" style="width=100%" class="mt-12 d-flex flex-column align-center text-center">
                <v-card color="" width="600">
                    <h1 class="mt-6 mb-12">
                        {{ $t('passwordchange.title') }}
                    </h1>

                    <form class="px-6">
                                <v-text-field type="password" v-model="pwd1" :color="negative_color_class"
                                    :label="$t('passwordchange.field.password1')" outlined />
                                <v-text-field type="password" v-model="pwd2" :color="negative_color_class"
                                    :label="$t('passwordchange.field.password2')" outlined />
                            </form>
                    <p class="text-center">{{ $t('passwordforgot.message.instruction') }} </p>



                    <div class="px-6 mt-12 mb-6">
                        <v-btn block color="secondary" @click="changePassword()" :disabled="pwd_change_disable">
                                <span class="primary--text">
                                    {{ $t('passwordchange.btn.change') }}
                                </span>
                            </v-btn>
                        <br>
                        <v-btn color="primary" text :to="{ name: 'Admin' }">
                                    <span class="secondary--text">
                                        {{ $t('passwordchange.btn.back') }}
                                    </span>
                                </v-btn>
                    </div>
                </v-card>
            </div>


<!-- 
            <v-row>
                <v-col cols=12>
                    <v-card v-if="isLogged" :color="positive_color_class" width="400">
                        <v-card-text>
                            <h1 class="mb-12">{{ $t('passwordchange.title') }}</h1>
                            <form>
                                <v-text-field type="password" v-model="pwd1" :color="negative_color_class"
                                    :label="$t('passwordchange.field.password1')" outlined />
                                <v-text-field type="password" v-model="pwd2" :color="negative_color_class"
                                    :label="$t('passwordchange.field.password2')" outlined />
                            </form>
                        </v-card-text>
                        <v-card-actions v-if="isLogged">
                            <v-btn block color="secondary" @click="changePassword()" :disabled="pwd_change_disable">
                                <span class="primary--text">
                                    {{ $t('passwordchange.btn.change') }}
                                </span>
                            </v-btn>
                        </v-card-actions>
                        <v-row no-gutters>
                            <v-col class="text-right pr-2 my-2" cols=12>
                                <v-btn color="primary" text :to="{ name: 'Admin' }">
                                    <span class="secondary--text">
                                        {{ $t('passwordchange.btn.back') }}
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => ({
        pwd1: "",
        pwd2: "",
        pwd_change_disable: false
    }),
    methods: {
        changePassword() {
            this.pwd_change_disable = true

            if (this.pwd1 != this.pwd2) {
                alert(this.$t('passwordchange.error.password_mismatch'))
                return
            }
            if (this.pwd1 == "") {
                alert(this.$t('passwordchange.error.password_empty'))
            }
            this.$store.dispatch("auth/act_pwd_change", this.pwd1).then((exit) => {
                console.log(exit);
                if (exit == false) {
                    alert(this.$t('passwordchange.alert.passwordChanged'))
                    this.$router.push({ name: 'Admin' })
                }
                this.pwd_change_disable = false

            }).catch(error => {
                alert(error)
            })
        }
    },
    mounted() { },
    created() { },
    computed: {},
    watch: {}
}
</script>

<style>

</style>